.card-card-div {
  color: rgb(88, 96, 105);
  background-color: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.2) 0px 10px 30px -15px;
  padding: 2rem;
  cursor: pointer;
  flex: 1 1 25%;
}
.card-card-div:hover {
  box-shadow: rgba(0, 0, 0, 0.2) 0px 20px 30px -10px;
}

.card-name-div {
  display: flex;
  align-items: center;
}

.card-name {
  text-align: left;
  color: rgb(36, 41, 46);
  font-family: "Google Sans Regular";
  margin-bottom: 0.75rem;
  font-size: 25px;
  font-weight: 700;
  letter-spacing: -0.5px;
  overflow: hidden;
  line-height: 1.2;
  margin: 0px;
}

.card-subtitle {
  overflow: hidden;
  font-size: 18px;
  text-align: left;
  display: -webkit-box;
  font-family: "Google Sans Regular";
  color: rgba(169, 169, 169, 0.657);
}

.card-description {
  overflow: hidden;
  text-align: left;
  display: -webkit-box;
  font-family: "Google Sans Regular";
  -webkit-box-orient: vertical;
  margin-top: 0.8rem;
}

.card-details {
  display: flex;
  flex-direction: row;
}

.card-creation-date {
  margin: 0;
  font-family: "Google Sans Regular";
  font-weight: 400;
  margin-left: 0px;
  margin-right: auto;
}

.card_img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  margin: auto;
  flex: 1 1 25%;
}

@media (max-width: 1200px) {
  .card-card-div {
    flex: 1 1 40%;
  }
}

@media (max-width: 768px) {
  .card-card-div {
    flex: 1 1 100%;
  }

  .card-name {
    font-size: 16px;
  }
  .card-subtitle {
    font-size: 12px;
  }
  .card-description {
    font-size: 14px;
  }

  .card-details {
    flex-direction: column;
  }

  .card-creation-date {
    font-size: 14px;
  }
}
